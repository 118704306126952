<template>
  <div>
    <div class="form-controls">
      <div class="export-options">
        <div class="export-format">
          <div>Format:</div>
          <div class="export-format-about" v-tooltip="{content: aboutGedcom}">
            <span>Gedcom 5.5</span>
            <help-icon :size="18"></help-icon>
          </div>
        </div>
        <div class="checkbox-control">
          <input v-model="includeImages" type="checkbox" id="includeImages" name="includeImages" />
          <label for="includeImages">Include all person images</label>
        </div>
        <div class="checkbox-control">
          <input v-model="includeLibrary" type="checkbox" id="includeLibrary" name="includeLibrary" />
          <label for="includeLibrary">Include other images in library</label>
        </div>
      </div>
      <div>
        <mcr-button @click="startDownload" :class="downloadButtonClasses" :disabled="isWaitingDownload"
          >Export
        </mcr-button>
      </div>
    </div>
    <div v-if="isWaitingDownload" class="result-container">
      <span>We are preparing your download. Please wait...</span>
      <mcr-loading-indicator :loading="isWaitingDownload"></mcr-loading-indicator>
    </div>
    <div v-if="downloadURL" class="result-container">
      <span>Your file is ready:</span><a :href="downloadURL" :download="fileName">Download</a>
    </div>
    <div v-if="hasError" class="error-container">
      <span v-if="errorText">{{ errorText }}</span>
      <span v-else
        >There was an error exporting your tree. Please try again later or
        <router-link to="{name: 'get-in-touch'}">contact us</router-link>
      </span>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import network from '@common/network/network';

const HelpIcon = () => import('vue-material-design-icons/HelpCircleOutline');

export default {
  name: 'ExportTreeContainer',
  props: {
    treeId: {
      type: [Number, String],
      required: true,
    },
    treeName: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      isWaitingDownload: false,
      exportFormat: 'gedcom5',
      downloadURL: null,
      fileName: `${this.treeName}.ged`,
      hasError: null,
      errorText: null,
      includeImages: false,
      includeLibrary: false,
      aboutGedcom:
        'Gedcom 5 is a standard file format for genealogical data.<br>It is supported by most genealogy software programs.',
    };
  },
  computed: {
    downloadButtonClasses() {
      return {
        disabled: this.isWaitingDownload,
      };
    },
  },
  methods: {
    startDownload() {
      this.isWaitingDownload = true;
      this.hasError = false;
      this.downloadURL = null;
      network.familyTree
        .exportTree({
          id: this.treeId,
          format: this.exportFormat,
          include_images: this.includeImages,
          include_library: this.includeLibrary,
        })
        .then(response => {
          this.downloadURL = 'data:text/plain;base64,' + btoa(unescape(encodeURIComponent(response)));
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            this.errorText = 'You are not authorized to export this tree.';
          }
          this.hasError = true;
        })
        .finally(() => {
          this.isWaitingDownload = false;
        });
    },
  },
  components: {McrButton, HelpIcon},
};
</script>

<style lang="scss" scoped>
.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  color: $error-color;
}

.form-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.control {
  margin: 15px 0;
}

.export-format {
  display: flex;
  align-items: center;

  .export-format-about {
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-left: 10px;
    .material-design-icon {
      margin-left: 4px;
      margin-bottom: 1px;
    }
  }
}

.mcr-loading-indicator {
  margin: 20px;
  transform: scale(0.5);
}
.checkbox-control {
  display: flex;
  margin-top: 5px;
  label {
    cursor: pointer;
  }
  input {
    cursor: pointer;
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
}
</style>
